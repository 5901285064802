import React, { useState } from 'react'

// Components
import IconsSwitch from '../../../components/IconsSwitch/_index'

import NorteadoresCulturaisJSON from '../../assets/data/InterTechNorteadores.json'

import * as S from './style'

type InterTechProps = {
  title: string;
  description: string;
  icon: string;
  path: string;
}

const NorteadoresCulturais = () => {
  const [ open, setOpen ] = useState(0)

  return (
    <section className='py-5 bg-orange--default'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-5 col-lg-4'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-30 lh-lg-38 lh-xl-52 text-white fw-500'>
              Norteadores culturais
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-white mt-3 mt-md-4'>
              Os norteadores culturais representam os valores que guiam nossas ações e decisões diárias. São fundamentais na jornada dos sangues laranjas e na construção do Inter do futuro.
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-7 offset-md-1 mt-3'>
            {
              NorteadoresCulturaisJSON.map((item: InterTechProps, index: number) => (
                <div className='bg-white mb-3 rounded-12 px-3 py-3' key={item.title}>
                  <div onClick={() => setOpen(open === index ? null : index)} className='d-flex justify-content-between cursor-pointer'>
                    <div className='d-flex justify-content-start'>
                      <div className='mr-2'>
                        <IconsSwitch
                          icon={item.icon}
                          width='24'
                          height='24'
                          color='#EA7100'
                          lib='interco'
                          customPath={item.path}
                        />
                      </div>
                      <div className='d-flex align-items-center cursor-pointer'>
                        <div><h3 className='fs-18 fs-md-20 lh-22 mb-0 fw-600 text-grayscale--500'>{item.title}</h3></div>
                      </div>
                    </div>
                    <S.Icon>
                      <IconsSwitch
                        icon='ic_chevron_down'
                        width='24'
                        height='24'
                        color='orange'
                        lib='interco'
                        customPath='action-navigation/'
                        className={`${open === index ? 'rotate' : ''}`}
                      />
                    </S.Icon>
                  </div>
                  {open === index && <div><p className='fs-14 fs-md-16 lh-17 lh-md-19 fw-400 mt-3 mb-0 text-grayscale--500'>{item.description}</p></div>}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default NorteadoresCulturais
