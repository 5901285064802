import React from 'react'

// Components
import Icon from '../../../components/Icon'
import ScrollToNew from '../../../components/ScrollToNew/index'

// Assets
import * as S from './style'

const InterTechHeader = () => {
  return (
    <S.Section className='pb-3'>
      <div className='container'>
        <div className='row align-itens-center'>
          <div className='col-12 col-md-10 mb-2 mb-md-0'>
            <p className='text-white fs-14 fs-md-16 lh-20 mb-0 text-md-right'>
              Venha destravar possibilidades para uma vida financeira inteligente!
            </p>
          </div>
          <div className='col-12 col-md-2 order-md-first'>
            <ScrollToNew
              elementName='Área de Atuação - Header Carreiras'
              to='#intertech'
              title='Área de Atuação'
              styles='link'
            >
              <p className='text-white fs-14 lh-20 mb-0'>
                Inter Tech <Icon color='white' directory='v2' icon='navigation/arrow-down' width={16} height={16} />
              </p>
            </ScrollToNew>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default InterTechHeader
