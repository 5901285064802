import React from 'react'

// Components
import VerticalCarousel from '../../../components/Carousels/VerticalCarousel/index'

// Assets
import * as S from './style'

import InterTechJSON from '../../assets/data/InterTech.json'

type InterTechProps = {
  title: string;
  description: string;
}

const InterTech = () => {
  return (
    <S.Section id='intertech' className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-3 col-lg-4'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-30 lh-lg-38 lh-xl-52 text-white text-center text-md-left fw-500'>
              Inter Tech
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-center text-md-left text-white mt-3 mt-md-4'>
              Somos movidos pelo propósito e medidos pela entrega!
            </p>
          </div>
          <div className='col-12 col-md-9 col-lg-8 mt-3'>
            <VerticalCarousel
              sm={{
                verticalItems: 1,
                horizontalItems: 1,
              }}
              md={{
                verticalItems: 1,
                horizontalItems: 2,
              }}
              lg={{
                verticalItems: 1,
                horizontalItems: 2,
              }}
              xl={{
                verticalItems: 1,
                horizontalItems: 2,
              }}
            >
              {InterTechJSON.map((item: InterTechProps) => (
                <S.Card className='rounded-6 px-3 py-4 mr-3' key={item.title}>
                  <h3>
                    <p className='fs-16 lh-20 text-orange--default fw-600' dangerouslySetInnerHTML={{ __html: item.title }} />
                  </h3>
                  <p className='fs-16 lh-20 text-gray--800 fw-400' dangerouslySetInnerHTML={{ __html: item.description }} />
                </S.Card>
              ))}
            </VerticalCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default InterTech
