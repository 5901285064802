import React from 'react'

// Components
import ImageWebp from '../../../components/ImageWebp'

// Assets
import * as S from './style'

const SuperAppFinanceiro = () => {
  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-7 d-flex justify-content-center'>
            <ImageWebp
              arrayNumbers={[ 312, 441, 603, 680 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-dobra2/image.webp'
              altDescription='Homem com roupa laranja sorrindo. Em uma das mãos está uma caneca marrom e na outra um telefone celular'
            />
          </div>
          <div className='col-12 col-md-5 mt-3'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-30 lh-lg-38 lh-xl-52 font-citrina text-white fw-500 text-center text-md-left'>
              Somos um Super App Financeiro
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-white text-center text-md-left mt-3'>
              Com ele, você resolve o seu dia a dia e planeja o futuro com eficiência, conveniência, economia e segurança. O Inter ainda te recompensa com pontos e cashback, conforme você aproveita o aplicativo.
            </p>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SuperAppFinanceiro
