import React, { ReactNode, MouseEvent, useState, useLayoutEffect } from 'react'
import ScrollToComponent from 'scroll-to'
import { addCustomDataInsideTag } from '../../shared/helpers'
import { WIDTH_MD, WIDTH_LG } from '../../utils/breakpoints'
import useWidth from '../../hooks/window/useWidth'
import useDataLayer from '../../hooks/useDataLayer/dataLayerBody'

type ScrollToProps = {
  to: string;
  title?: string;
  children: ReactNode;
  styles?: string;
  dataAttributeName?: string;
  dataAttributeValue?: string;
  className?: string;
  onClick?: Function;
  section?: string;
  sectionName?: string;
  elementName?: string;
  ariaLabel?: string;
}

export function scrollTo (evt: MouseEvent<HTMLAnchorElement>, positionNumber: number) {
  evt.preventDefault()

  const topElement = document.getElementById(evt.currentTarget.hash.replace('#', ''))
  if (!topElement) { return }
  const offsetTopElem = topElement.getBoundingClientRect()
  const top = offsetTopElem.top + window.scrollY - positionNumber

  ScrollToComponent(0, top, {
    ease: 'in-out-quad',
    duration: 1100,
  })
}

function ScrollTo ({ to, children, styles, title, dataAttributeName, dataAttributeValue, onClick, section, sectionName, elementName, ariaLabel }: ScrollToProps) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const customDataAttribute = (dataAttributeName && dataAttributeValue)
  ? { ...addCustomDataInsideTag(dataAttributeName, dataAttributeValue) }
  : {}

  const windowWidth = useWidth(300)
  const [ positionNumber, setPositionNumber ] = useState(147)

  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    scrollTo(evt, positionNumber)
    if (onClick) {
      onClick()
    }
  }

  useLayoutEffect(() => {
    if (windowWidth < WIDTH_MD) {
      setPositionNumber(147)
    } else if (windowWidth >= WIDTH_MD && windowWidth < WIDTH_LG) {
      setPositionNumber(184)
    } else {
      setPositionNumber(64)
    }
  }, [ windowWidth ])

  return (
    <a
      href={to}
      title={title}
      className={styles} {...customDataAttribute}
      aria-label={ariaLabel}
      onClick={(evt: MouseEvent<HTMLAnchorElement>) => {
        handleClick(evt)
        sendDatalayerEvent({
          section: section,
          section_name: sectionName,
          element_name: elementName,
          element_action: 'click scroll',
          redirect_url: window.location.href + to,
        })
      }}
    >
      {children}
    </a>
  )
}

export default ScrollTo
