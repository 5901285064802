import React from 'react'

// Components
import ImageWebp from '../../../components/ImageWebp'

// Assets
import * as S from './style'

const Plano = () => {
  return (
    <section className='py-5 bg-orange--extralight'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-7 col-lg-6'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-30 lh-lg-38 lh-xl-52 text-orange--argila fw-500'>
              Plano 60 | 30 | 30
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-grayscale--500 mt-3 mt-md-4'>
              É o plano que norteia nosso trabalho rumo ao objetivo de obter R$5BI de lucro líquido em 2027. A certeza de que temos um time capaz engajado e com um propósito único nos move a acreditar nesse sonho e correr atrás para alcançá-lo.
            </p>
            <S.Card className='d-flex align-items-center justify-content-between mt-4 px-4 py-4'>
              <div>
                <p className='fs-14 fs-md-16 fs-xl-20 lh-16 lh-md-19 lh-xl-24 fw-600 text-center text-white mb-0'>
                  <span className='d-block mb-2'>60 MM</span><span className='fw-400'>de clientes</span>
                </p>
              </div>
              <S.Bar />
              <div>
                <p className='fs-14 fs-md-16 fs-xl-20 lh-16 lh-md-19 lh-xl-24 fw-600 text-center text-white mb-0'>
                  <span className='d-block mb-2'>30%</span>
                  <span className='d-block fw-400'>de índice</span> de eficiência
                </p>
              </div>
              <S.Bar />
              <div>
                <p className='fs-14 fs-md-16 fs-xl-20 lh-16 lh-md-19 lh-xl-24 fw-600 text-center text-white mb-0'>
                  <span className='d-block mb-2'>30%</span><span className='fw-400'>de ROE</span>
                </p>
              </div>
            </S.Card>
          </div>
          <div className='col-12 col-md-5 offset-lg-1 d-flex justify-content-center mt-3'>
            <ImageWebp
              arrayNumbers={[ 312, 260, 376, 456 ]}
              arrayNumbersHeight={[ 312, 260, 376, 456 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/carreira-dobra3/image.webp'
              altDescription='Sede do Inter em Belo Horizonte
'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Plano
