import React, { FunctionComponent, ComponentClass } from 'react'
import Loadable, { LoadableComponent } from 'react-loadable'
import { LoadingIndicator } from 'inter-site-components'

interface IIcon {
  color: string;
  className?: string;
 }
 type OrangeIconType = {
  size?: string;
  icon: string;
  color?: string;
  className?: string;
 }
function OrangeIcon ({ size, icon, color, className }: OrangeIconType) {
  const Icon: (ComponentClass<IIcon> & LoadableComponent) | (FunctionComponent<IIcon> & LoadableComponent) = Loadable({
    loader: () => import(`inter-frontend-svgs/lib/orangeds/${size}/${icon}`),
    loading: () => <LoadingIndicator size='small' />,
  })
  return (
    <Icon color={color} className={className} />
  )
}

export default OrangeIcon
