import styled from 'styled-components'
import { Link } from 'gatsby'
import { orange, white } from '../../../styles/colors'

export const Btn = styled(Link)`
  width: 100%;
  height: 48px;
  background: ${orange.default}!important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    background: ${orange.default}!important;
    outline: none;
    text-decoration: none;
    color: ${white};
    opacity: 0.9;
  }
`
