
import React from 'react'

// components
import Layout from '../components/Layout'

// sections
import InterTechHeader from './sections/inter-tech-header/_index'
import Hero from './sections/hero/_index'
import SuperAppFinanceiro from './sections/super-app-financeiro/_index'
import Plano from './sections/plano/_index'
import NossaTrajetoria from './sections/nossa-trajetoria/_index'
import InterTech from './sections/inter-tech/_index'
import NorteadoresCulturais from './sections/norteadores-culturais/_index'
import NossosEscritorios from './sections/nossos-escritorios/_index'
import FiquePorDentro from './sections/fique-por-dentro/_index'

// assets
import { Wrapper } from './style'

import pageContext from './pageContext.json'

const Home = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <InterTechHeader />
        <Hero />
        <SuperAppFinanceiro />
        <Plano />
        <NossaTrajetoria />
        <InterTech />
        <NorteadoresCulturais />
        <NossosEscritorios />
        <FiquePorDentro />
      </Layout>
    </Wrapper>
  )
}

export default Home
