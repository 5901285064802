import React from 'react'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Assets
import * as S from './style'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section
      role='img'
      aria-label='Jovem de cabelos cacheados e óculos sorrindo'
      className='py-5 d-flex align-items-md-center'
    >
      <div className='container d-flex align-items-end'>
        <div className='row'>
          <div className='col-12 col-md-5'>
            <h1 className='text-white fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-33 lh-lg-38 lh-xl-55 fw-500'>
              <span className='d-lg-block'>Construa sua</span> carreira no Inter
            </h1>
            <p className='text-white fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 mt-lg-4'>
              Venha fazer parte do Super App Financeiro e colabore para planejar o futuro com eficiência e segurança, enquanto constrói seu futuro em uma equipe inovadora.
            </p>
            <S.Btn
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_1',
                  element_action: 'click button',
                  element_name: 'Conferir as vagas',
                  section_name: 'Construa sua carreira no Inter',
                  redirect_url: 'carreiras',
                })
              }}
              to='carreiras'
              title='Veja nossas vagas disponíveis'
              className='fs-14 fw-600 text-none mb-0 mw-100 px-5 py-2 mt-2 mt-lg-4'
            >
              Conferir as vagas
            </S.Btn>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
