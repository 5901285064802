import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import { orange, white, brand } from '../../../styles/colors'
import Arrow from '../../assets/images/arrow.png'

export const Section = styled.section`
  background-color: ${orange.argila};

  ul.react-multi-carousel-dot-list {
    li.react-multi-carousel-dot {
      button {
        border: 0;
        background-color: ${white}!important;
        height: 8px;
        width: 8px;
        opacity: 0.5;
      }
      &--active {
        button {
          background-color: ${white}!important;
          opacity: 1;
        }
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    right: calc(20% + 1px);

    &:before  {
      background-image: url(${Arrow});
    }
  }

  .react-multiple-carousel__arrow--left {
    left: calc(20% + 1px);
    
    &:before  {
      background-image: url(${Arrow});
    }
`

export const Card = styled.div`
  background-color: ${brand.sand};
  border-radius: 15px;

  @media (min-width: ${breakpoints.md}) {
    height: 284px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 254px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 214px;
  }
`
