import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import { Link } from 'gatsby'
import { orange, white } from '../../../styles/colors'

export const Section = styled.section`
  height: 700px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-hero-360/image.webp');
  background-size: cover;
  background-repeat: no-repeat;

  span {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  @media (min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-hero-768/image.webp');
    background-size: cover;
    height: 502px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-hero-1024/image.webp');
    background-size: cover;
    height: 680px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-hero-1024/image.webp');
    background-size: cover;
    height: 723px;
  }
`

export const Btn = styled(Link)`
  width: 100%;
  height: 48px;
  background: ${white};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.default};
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    background: ${white};
    outline: none;
    text-decoration: none;
    color: ${orange.default};
    opacity: 0.9;
  }
`
