import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { orange, grayscale } from '../../styles/colors'

export const TabNav = styled.nav`

  ul {

    li {
      flex: 1;
      text-align: center;
      border-bottom: 3px solid ${orange[200]};

      button {
        color: ${grayscale[400]};
        font-weight: 600;
        cursor: pointer;
        outline: none;
        padding: 10px;
      }

      &.active {
        border-bottom: 3px solid ${orange.base};

        button {
          color: ${orange.base};
        }
      }
    }
  }

`

export const TabContent = styled.div`
  padding-top: 50px;

  > div {
    display: none;

    &.active {
      display: block;
    }
  }
`
