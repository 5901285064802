import React from 'react'

// Components
import Tab from '../../../components/Tab/index'
import ImageWebp from '../../../components/ImageWebp/index'

// Hooks
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Assets
import * as S from './style'

import NossosEscritoriosJSON from '../../assets/data/NossosEscritorios.json'

type NossosEscritorios = {
  title: string;
  description: string;
  image: string;
  link: string;
}

const NossosEscritorios = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-28 fs-lg-32 fs-xl-48 lh-28 lh-md-30 lh-lg-38 lh-xl-52 text-orange--argila text-md-left fw-500'>
              Nossos escritórios
            </h2>
          </div>
        </div>
        <Tab buttonClassName='fs-12 fs-md-14 fs-lg-16 lh-14 lh-md-16 lh-lg-19' items={NossosEscritoriosJSON.map((item: NossosEscritorios) => item.title)}>
          {NossosEscritoriosJSON.map((item: NossosEscritorios) => (
            <div className='row align-items-center' key={item.title}>
              <div className='col-12 col-md-6 col-lg-5'>
                <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-21 text-grayscale--400' dangerouslySetInnerHTML={{ __html: item.description }} />
                <S.Btn
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_7',
                      element_action: 'click button',
                      element_name: `Conferir as vagas - ${item.title}`,
                      section_name: 'Nossos Escritórios',
                      redirect_url: item.link,
                    })
                  }}
                  to={item.link}
                  title='Veja nossas vagas disponíveis'
                  className='fs-14 text-center rounded-8 mt-4 mt-md-2'
                >
                  Conferir as vagas
                </S.Btn>
              </div>
              <div className='col-12 col-md-5 col-lg-6 offset-md-1 mt-4 mt-md-0 d-flex justify-content-center justify-content-md-end'>
                <ImageWebp
                  arrayNumbers={[ 312, 274, 424, 541 ]}
                  pathSrc={item.image}
                  altDescription={item.title}
                />
              </div>
            </div>
          ))}
        </Tab>
      </div>
    </section>
  )
}

export default NossosEscritorios
