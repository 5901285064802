import React, { ReactNode, useState } from 'react'

import { TabNav, TabContent } from './style'

interface ITab {
  items: string[];
  children: ReactNode[];
  buttonClassName?: string; // Adicionando a prop opcional
}

const Tab: React.FC<ITab> = ({ items, children, buttonClassName }: ITab) => {
  const [ active, setActive ] = useState(0)

  return (
    <>
      <TabNav>
        <ul className='list-unstyled d-flex align-items-center justify-content-around'>
          {
            items.map((item: string, index: number) => (
              <li className={`${active === index ? 'active' : ''}`} key={item}>
                <button
                  className={`border-0 bg-transparent ${buttonClassName || ''}`}
                  type='button'
                  onClick={() => setActive(index)}
                >
                  {item}
                </button>
              </li>
            ))
          }
        </ul>
      </TabNav>
      <TabContent>
        {
          children.map((itemChidren: ReactNode, index: number) => (
            <div key={index} className={`${active === index ? 'active' : ''}`}>
              {itemChidren}
            </div>
          ))
        }
      </TabContent>
    </>
  )
}

export default Tab
