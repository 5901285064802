import styled from 'styled-components'
import { orange, white } from '../../../styles/colors'

export const Card = styled.div`
  background-color: ${orange.default};
  border-radius: 15px;
  height: 109px;
`

export const Bar = styled.div`
  border: 1px solid ${white};
  height: 80px;
`
