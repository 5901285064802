import styled from 'styled-components'
import { breakpoints } from '../../../styles/breakpoints'

export const Section = styled.section`
  background-color: #943D15;
  padding-top: 79px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 98px;
  }
`
